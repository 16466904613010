.landing-page {
    .landing-image {
        width: 100%;
        border-radius: 1em;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.418);
        padding: .5em;
        
    }

    .left-col {
        text-align: center;
        padding: 1em;
    }

    .right-col {
        text-align: center;
        padding: 1em;

        h2 {
            text-align: center;
            color: var(--primary-color);
        }
    }
}