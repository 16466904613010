@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&family=Open+Sans+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@900&display=swap');

:root {
    --primary-color: #2fb4f9;
    --light-purple: #e9e7f4;
    --prim-color: #64485c;
    --sec-color: #999999;
    --dark-grey: #494949;
    --third-color: #cccccc;
    --fourth-color: #f0f0f0;
    --theme-hover-color: #ffffff;
}

html {

}

.content-container {
  min-height: calc(100vh - 60px);
  padding: 0 1em;
}

.footer-container {
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
}

*, :focus {
  outline: none !important;
  outline-offset: 0px;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  font-family: 'Lexend', sans-serif;
  box-sizing: border-box;
  color:rgb(109, 109, 109)
}

main {
    text-align: center;
    vertical-align: center;
    line-height: 100vh
}

a {
  text-decoration: none !important;
  color: var(--dark-grey) !important;

  &.white {
    color: white !important;
    font-weight: bold;
  }
}

.hide {
  display:none;
}

.content-container {
    min-height: calc(100vh - 60px);
    padding: 0 1em;
}

.g-container {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  // padding: 0 14px;

  &.tight {
    max-width: 800px;
  }

}

.g-container.no-pad {
  max-width: 80%;
  margin: auto;
}

.center {
  text-align: center;
}

.g-card {
	background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
  padding: 20px 30px;
  border-radius: 10px;
  transition: .5s;

	&.pad {
		margin: 14px 0;
	}

  &.button-card:hover {
    transform: translateY(-2px);
    box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 10%);
  }
}

.g-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: stretch;

  // * > :first-child { 
  // 	margin-top: 0 !important; 
  // 	margin-left: 0 !important; 
  // }
  
  // * > :last-child { 
  // 	margin-bottom: 0 !important; 
  // 	margin-right: 0 !important; 
  // }

  .g-col {
      width: 100%;
  }
  
  .g-half-col {
      width: 48%;
  }

  .g-qtr-col {
      width: 24%;
  }

  .g-third-col {
      width: 33%;
  }

  .g-two-third-col {
      width: 66%;
  }

  .g-three-qtr-col {
      width: 74%;
  }

}


.uk-button {
  cursor: pointer !important;
}


/* CSS */
input[type="file"]::file-selector-button,
.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: var(--dark-grey);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-evenly;
  line-height: 1.25;
  margin: 0 5px;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &.padded {
    margin: 10px;
  }

  


  
  &.minor {
      background-color: transparent;
      color: white;
      border: 2px solid rgba(255, 255, 255, .5);

      &:hover {
          background-color: rgba(255, 255, 255, 1);
          color: var(--prim-color);
      }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: var(--prim-color);
  }
}

.button-6.small {
    padding: 7px;
    line-height: 20px;
    min-height: 0px;
}

.button-6.primary {
  // background-color: #d0a0ba;
  // color: white;

  &:hover {
    background-color: var(--gamably-color);
    color: white;
  }
}

// input[type="file"]::file-selector-button {
//   width: 200px;
//   overflow: hidden;
//   margin: 10px 0;
// }


// #iconUpload {
//   color:transparent;
//   width: 200px;
//   overflow: hidden;
// }

input[type="file"]::file-selector-button:hover,
.button-6:hover {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: var(--gamably-color);
  
}

.button-6:hover {
  transform: translateY(-3px);

  &.static {
    transform: none;
  }
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.button-6.disabled {
  color:#999999 !important;
  cursor: auto;
  background-color: #e7e7e7;

  &:hover {
    transform: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  }

  &:active {
    transform: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    touch-action: none;
    
  }
}

/** 
* FORM
*/

label {
  display: flex;
  padding-bottom: 14px;
}

.field {
    padding-bottom: 24px;
}

.field input,
.field select {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    color: slategray; 
    font-family: 'Lexend', sans-serif;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, 
                rgb(84, 105, 212) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}
.field-checkbox input {
    width: 20px;
    height: 15px;
    margin-right: 5px; 
    box-shadow: unset;
    min-height: unset;
}
.field-checkbox label {
    display: flex;
    align-items: center;
    margin: 0;
}


/*
*   TABLE
*/


.g-table {
    width: 100%;
    background: #FFFFFF;
    margin: 1em 0em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0px;
    font-size: 1em;

    tr {
    
        td {
            border-top: 1px solid rgba(34, 36, 38, 0.1);
            padding: 0.8em 0.8em;
            text-align: inherit;
        }
    }

    tr:first-child td {
        border-top: none;
    }

}

.g-table:last-child {
    margin-bottom: 0em;
}


/*
* Grid
*/

/*
* Spacers
*/

.g-space-20 {
  height: 20px;
}

.g-space-40 {
  height: 40px;
}

.g-space-60 {
  height: 60px;
}

@media (max-width: 920px) {
  .g-grid {
      display: block;

      .g-col,
      .g-half-col,
      .g-qtr-col,
      .g-third-col, 
      .g-two-third-col,
      .g-three-qtr-col {
          width: 100%;
      }
  }


  
  
}