.head-container {
	height: 64px;

	#header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 64px;
		background-color: #ffffffde;
		color: var(--primary-color);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: top 0.3s ease-out;
		z-index: 999;

		.header-content {
			width: 100%;
			max-width: 1150px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			
            .left {
                display: flex;
                align-items: center;
                cursor: pointer;

                h4 {
                    color: var(--primary-color);
                    margin: 0;
                    font-weight: bold;
                }

                .logo {
                    margin: 0 12px;
                    height: 40px;
                    cursor: pointer;
                }
            }

			.right {

				display: flex;
				align-items: center;

				a {
					text-decoration: none;
				}
				
				.top-link {
					display: inline-block;
					padding: 0 12px;
					color: var(--primary-color);
					font-weight: bold;
					box-sizing: border-box;
					cursor: pointer;
					line-height: 50px;
					height: 50px;
					border-radius: 5px;
					
					&:hover {
						background-color:#fff;
						color: var(--gamably-color)
					}

                    .header-name-icon {
                        display: flex;
                        align-items: center;

                    }
					
				 }
	
				.menu_spacer {
					display: inline-block;
					width: 1.5px;
					height: 1.75em;
					margin: 0 0.25em;
					background: rgba(255,255,255,0.4);
				}
			}
		}
		
	}
}

.right-content-narrow {
	display: none;
	padding-right: 20px;
}

.header-user-icon {
	width: 30px;
	border-radius: 50%;
	margin-right: 12px;
}

.navbar {
	text-align:left;
	// height: 45px;
	
	display: flex;
	justify-content: space-between;

	.nav-icon {
		height: 40px;
	}

	.toggle-icon {
		color: white;
		vertical-align: middle;
	}
	
	.nav-icon-mobile {
		line-height: 50px;
		// max-height: 44px;
	}

	.left {
		padding: 0;
		margin: 0;
        display: flex;
        align-items: center;
	}


	.left-mobile {
		padding: 0 24px 0 0;
	}
	
	.right {
		
		
		a {
			text-decoration: none;
		}
		
		.top-link {
			padding: 12px;
			color: white;
			font-weight: bold;
			box-sizing: border-box;
			cursor: pointer;
			
		}

        .menu_spacer {
            display: inline-block;
            width: 1.5px;
            height: 1.75em;
            margin: 0 0.25em;
            background: rgba(255,255,255,0.4);
        }
	}
}

.right-content-wide {
	line-height: 30px;
	display: flex;
	align-items: center;
}

.head-spacer {
	height: 70px;
	// display: block;
}


@media (max-width: 650px) {
	
    // .head-container {
    //     display: none;
    // }

    .app-name {
        display: none;                       
    }
    
    .right-content-wide {
		display: none;
	}

	.right-content-narrow {
		display: inline-block;
	}
}