.image-container {
    // box-sizing: content-box;
    // position: relative;
    height: 420px;
    width: 100%;
    max-height: 420px;
    // max-width: 420px;
    

    

        // .image {
        //     width: 220px;
        //     height: 220px;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     transition: all .3s ease-in-out;
        //     border-radius: 1em;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     padding: .5em;
            
        //     &:hover {
        //         width: 240px;
        //         height: 240px;
        //     }
            

            
            
        // }

        // .overlay {
        //     display: none;
        //     position: absolute;
        //     width: 220px;
        //     height: 220px;
        //     transition: all .3s ease-in-out;
        //     border-radius: 1em;
        //     background-color: #58c33777;

        //     &.chosen {
        //         display: block;
        //     }

        //     &:hover {
        //         width: 240px;
        //         height: 240px;
        //     }
        // }
    
}

.image {
    height: 420px;
    width: 100%;
    // max-height: 420px;
    max-width: 420px;
    border-radius: 1em;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all .3s ease-in-out;
    // position: relative;
    top: 0;
    left: 0;

    &.ready {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.418);
    }
    
    &.ready:hover {
        box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.251);
    }

    &.chosen {
        height: 420px;
        width: 420px;
        pointer-events: none;
        box-shadow: 0px 0px 16px 16px rgba(24, 207, 0, 0.447);
        // background-color: #58c337;  /* fallback colour. Make sure this is just one solid colour. */
        // background-color: -webkit-linear-gradient(rgba(46, 197, 0, 0.8), rgba(60, 228, 18, 0.8));
        // background-color: linear-gradient(rgba(46, 197, 0, 0.8), rgba(60, 228, 18, 0.8)); /* The least supported option. */
    }
}

@media (max-width: 650px) {
    
    .image {
        height: 300px;

        &.ready:hover {
            width: 100%;        
        }

        &.chosen {
            width: 100%;
            height: 300px;
        }
    }
}