.stats-page {
    
    .stats-head {
        text-align:center;
    }

    h2 {
        text-align:center;
        font-weight: bold;
    }

    .stat-image {
        width: 70px;
    }
}