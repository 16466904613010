.set-list-container {
    .list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.list-buttons {
    text-align:right;
    width: 40%;
}