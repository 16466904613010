footer {
    width: 100%;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
	background-color:#2fb4f9 !important;
	color: white;
	padding:0px 0px 0px !important;
	font-size: .65em;

    .footer-content {
        text-align: center;
    }

    a, a.footer-link:hover,
    a.footer-link {
        color: rgba(255, 255, 255, 0.7) !important;
    }
}