.dropdown-container {
    height: 0px;
    width: 160px;
    background-color: #fff;
    border-radius: 1em;
    z-index: 999;
    transition: height .3s ease-in-out;
    overflow: hidden;
    position: absolute;
    top: 60px;
    // right: 20%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    &.active {
        height: 64px;
    }

    .dropdown-menu {
        padding: 20px;

        & > a {
            text-decoration: none;
            color: var(--gamably-color);
            display: block;
            line-height: 24px;
        }
    
    }
}