.upload-form {
	.upload-btn-wrapper {
 	 	position: relative;
		overflow: hidden;
		display: inline-block;

		input[type=file] {
  			font-size: 100px;
		  	position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			// display: none;
		}
	}

	.thumbs {
		margin: 20px 0;
		
		.upload-thumb {
			width: 100%;
			max-width: 130px;
			margin: 8px;
		}
	}
}
