.comparison-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    // flex-direction: column;
    // height: 220px;
    overflow: visible;

    .vs {
        width: 60px;
        margin: 10px 0;
    }
}

@media (max-width: 650px) {

    .comparison-container {
        flex-direction: column;
        padding: 0 2em;
    }

}