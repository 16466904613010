.slide-menu-container {
    position: relative;

    .slide-head {

        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            max-width: 50px;
        }

        .close-icon {
            color: var(--primary-color);
            font-weight: bold;
        }
    }
    
}

.slide-menu {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 80%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    transition: left 0.3s ease-in-out;
    z-index: 999;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.178);
}

.slide-menu.active {
    left: 0;
}

.top-link {
    display: inline-block;
    padding: 0 12px;
    color: var(--primary-color);
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 50px;
    height: 50px;
    border-radius: 5px;
    font-size: 1.2em;
    
    &:hover {
        background-color:#fff;
        color: var(--gamably-color)
    }

    .header-name-icon {
        display: flex;
        align-items: center;

    }

    .slide-icon {
        margin-right: 20px;
    }
    
 }